// App.js

import React, { useState, useEffect, useRef } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
  Navigate
} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faTasks,
  faLineChart,
  faPlusCircle,
  faClipboardList,
  faBell,
  faBars,
  faUserGroup
} from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import { useAuth } from './AuthContext';
import { UserProvider, useUser } from './UserContext';
import { NotificationProvider, useNotifications } from './NotificationContext'; // Added NotificationContext

import Login from './Login';
import Register from './Register';
import Home from './Home';
import Items from './Items';
import Wishes from './Wishes';
import About from './About';
import FAQ from './FAQ';
import Admin from './Admin';
import Feedback from './Feedback';
import MyActivity from './MyActivity';
import Settings from './Settings';
import ProtectedRoute from './ProtectedRoute';

import PeopleIcon from '@mui/icons-material/People';
import LoopIcon from '@mui/icons-material/Loop';

import 'bootstrap/dist/css/bootstrap.min.css';

import AddItemModal from './AddItemModal';
import AddWishModal from './AddWishModal';
import ResetPassword from './ResetPassword';
import ChangePassword from './ChangePassword';

import './App.css';

// NEW: import SharedLibrary component
import SharedLibrary from './SharedLibrary';

const adminUserIds = [1, 2]; // Example admin user IDs

// A tiny helper component for the two-icon look:
const LoginIcon = () => (
  <div className="login-icon" style={{ position: 'relative' }}>
    <PeopleIcon style={{ fontSize: 24, color: '#4CAF50' }} />
    <LoopIcon
      style={{
        position: 'absolute',
        top: '50%',
        left: '100%',
        transform: 'translate(-50%, -50%)',
        fontSize: 12,
        color: '#4CAF50',
        opacity: 0.6
      }}
    />
  </div>
);

function AdminRoute({ children }) {
  const { userId } = useUser();
  const isAdmin = adminUserIds.includes(userId);

  if (!userId) {
    return <Navigate to="/login" />;
  } else if (!isAdmin) {
    return <Navigate to="/items" />;
  }
  return children;
}

/**
 * A minimal Header with the hamburger button in top-left
 */
function Header({ toggleMenu, buttonRef }) {
  return (
    <header className="header">
      {/* Hamburger Button on the Left */}
      <button
        ref={buttonRef}
        className="hamburger-btn"
        type="button"
        onClick={toggleMenu}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>

      {/* Logo with Tagline and Icon */}
      <div className="logo-container">
        <Link to="/items" className="branding-link">
          <div className="branding">
            <span>
              Your Community's Library of Things <LoginIcon />
            </span>
          </div>
        </Link>
      </div>
    </header>
  );
}

/**
 * BottomNav is the scroll-hiding nav bar.
 * We pass in the click handlers (onOfferClick, onLendClick, onWishClick)
 * from the parent so we can call them from the "Post" sub-menu.
 */

function BottomNav({
  toggleMenu,
  isOpen,
  notifications,
  totalNotifications, // New prop
  pendingRequestsCount, // New prop for pending requests
  previewMode,
  showNavRef,
  selectedIcon,
  setSelectedIcon,
  onOfferClick,
  onLendClick,
  onWishClick
}) {
  // Toggle for showing the "Offer/Lend/Wish" sub-menu
  const [showPostMenu, setShowPostMenu] = useState(false);
  const postMenuRef = useRef(null);

  // Effect to close the post menu when a different icon is selected
  useEffect(() => {
    if (selectedIcon !== 'plus') {
      setShowPostMenu(false);
    }
  }, [selectedIcon]);

  // Effect to handle clicks outside the popup menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the post menu and the 'Post' button
      if (
        postMenuRef.current &&
        !postMenuRef.current.contains(event.target) &&
        event.target.getAttribute('aria-label') !== 'Post'
      ) {
        setShowPostMenu(false);
        // Optionally, reset the selected icon if needed
        // setSelectedIcon(''); 
      }
    };

    // Attach the event listener when the post menu is open
    if (showPostMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts or when showPostMenu changes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPostMenu]);

  // Handler for clicking navigation icons
  const handleNavClick = (icon) => {
    setSelectedIcon(icon);
    if (icon !== 'plus') {
      setShowPostMenu(false);
    }
  };

  return (
    <div
      className="bottom-nav-container"
      style={{
        transform: showNavRef.current ? 'translateY(0)' : 'translateY(100%)',
        transition: 'transform 0.3s'
      }}
    >
      <nav className="bottom-nav">
        <ul>
          {/* Activity Icon with Notification Badge */}
          <li className={selectedIcon === '/home' ? 'active-link' : ''}>
            <Link
              to="/myactivity"
              className="nav-icon"
              onClick={() => handleNavClick('/home')}
              aria-label="Activity"
            >
              <div style={{ position: 'relative' }}>
                <FontAwesomeIcon icon={faBell} />
                {totalNotifications > 0 && (
                  <span className="notification-badge">{totalNotifications}</span>
                )}
              </div>
              <span className="nav-text">Activity</span>
            </Link>
          </li>

          {/* Items Icon */}
          <li className={selectedIcon === '/items' ? 'active-link' : ''}>
            <Link
              to="/items"
              className="nav-icon"
              onClick={() => handleNavClick('/items')}
              aria-label="Items"
            >
              <div>
                <FontAwesomeIcon icon={faTasks} />
                <span className="nav-text">Items</span>
              </div>
            </Link>
          </li>

          {/* "Post" icon with sub-menu */}
          <li className={selectedIcon === 'plus' ? 'active-link' : ''}>
            <button
              className="nav-icon"
              onClick={() => {
                setShowPostMenu(!showPostMenu);
                setSelectedIcon('plus');
              }}
              aria-label="Post"
              aria-expanded={showPostMenu}
            >
              <div>
                <FontAwesomeIcon icon={faPlusCircle} />
                <span className="nav-text">Post</span>
              </div>
            </button>
            {showPostMenu && (
              <div
                className={`post-menu ${showPostMenu ? '' : 'hide'}`}
                role="menu"
                ref={postMenuRef}
              >
                <ul>
                  <li
                    role="menuitem"
                    tabIndex="0"
                    onClick={() => {
                      onOfferClick();
                      setShowPostMenu(false);
                      setSelectedIcon('plus'); // Keep 'plus' selected
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onOfferClick();
                        setShowPostMenu(false);
                        setSelectedIcon('plus');
                      }
                    }}
                  >
                    Offer
                  </li>
                  <li
                    role="menuitem"
                    tabIndex="0"
                    onClick={() => {
                      onLendClick();
                      setShowPostMenu(false);
                      setSelectedIcon('plus'); // Keep 'plus' selected
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onLendClick();
                        setShowPostMenu(false);
                        setSelectedIcon('plus');
                      }
                    }}
                  >
                    Lend
                  </li>
                  <li
                    role="menuitem"
                    tabIndex="0"
                    onClick={() => {
                      onWishClick();
                      setShowPostMenu(false);
                      setSelectedIcon('plus'); // Keep 'plus' selected
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onWishClick();
                        setShowPostMenu(false);
                        setSelectedIcon('plus');
                      }
                    }}
                  >
                    Wish
                  </li>
                </ul>
              </div>
            )}
          </li>

          {/* Wishes Icon */}
          <li className={selectedIcon === '/wishes' ? 'active-link' : ''}>
            <Link
              to="/wishes"
              className="nav-icon"
              onClick={() => handleNavClick('/wishes')}
              aria-label="Wishes"
            >
              <div>
                <FontAwesomeIcon icon={faClipboardList} />
                <span className="nav-text">Wishes</span>
              </div>
            </Link>
          </li>

          {/* Network Icon with Notification Badge */}
          <li className={selectedIcon === '/settings' ? 'active-link' : ''}>
            <Link
              to="/settings"
              className="nav-icon"
              onClick={() => handleNavClick('/settings')}
              aria-label="Network"
            >
              <div style={{ position: 'relative' }}>
                <FontAwesomeIcon icon={faUserGroup} />
                {pendingRequestsCount > 0 && (
                  <span className="notification-badge">{pendingRequestsCount}</span>
                )}
              </div>
              <span className="nav-text">Network</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

// A separate component so we can call useLocation() inside BrowserRouter
function AppContent() {
  // Auth and user info
  const { isLoggedIn, logout, previewMode } = useAuth();
  const { userId } = useUser();

  // For the hamburger menu
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  // Manage showing/hiding the AddItem and AddWish modals
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showAddWishModal, setShowAddWishModal] = useState(false);

  // Track if the posted item is a “giveaway” (true) or “lend” (false)
  const [isGiveaway, setIsGiveaway] = useState(false);

  // For the scroll-based nav hide
  const showNavRef = useRef(true);
  const lastScrollY = useRef(window.scrollY);

  // Admin logic
  const isAdmin = userId && adminUserIds.includes(userId);

  // Notifications from context
  const { notifications, fetchNotifications } = useNotifications();

  // Calculate the total number of notifications
  const totalNotifications = notifications.approve_interests +
    notifications.approved_new_requests +
    notifications.new_matching_wishlist;

  // Extract pending requests count for Network
  const pendingRequestsCount = notifications.pending_requests;

  // Track the icon selected in bottom nav
  const [selectedIcon, setSelectedIcon] = useState('/items');

  

  // React Router location for auto-updating selected icon
  const location = useLocation();

  // If the route starts with /share/, it's a public library link
  const isShareRoute = location.pathname.startsWith('/share/');
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Hide menu if user clicks outside
  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  // Functions the bottom nav will call:
  const handleOfferClick = () => {
    setIsGiveaway(true);       // Mark as a giveaway item
    setShowAddItemModal(true); // Show the AddItemModal
  };

  const handleLendClick = () => {
    setIsGiveaway(false);      // Mark as a "lend" item
    setShowAddItemModal(true);
  };

  const handleWishClick = () => {
    // Wish doesn't care about “giveaway” or “lend”
    setShowAddWishModal(true);
  };

  // Hide/show bottom nav on scroll
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY.current) {
      // user scrolling DOWN
      showNavRef.current = false;
    } else {
      // user scrolling UP
      showNavRef.current = true;
    }
    lastScrollY.current = currentScrollY;
  };

  // Fetch notifications (handled by context)
  // Already fetched via useNotifications

  // Attach scroll listener / click outside
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  // Whenever the route changes, set the selectedIcon accordingly
  useEffect(() => {
    if (location.pathname.startsWith('/items')) {
      setSelectedIcon('/items');
    } else if (
      location.pathname.startsWith('/home') ||
      location.pathname.startsWith('/myactivity')
    ) {
      setSelectedIcon('/home');
    } else if (location.pathname.startsWith('/wishes')) {
      setSelectedIcon('/wishes');
    } else if (location.pathname.startsWith('/settings')) {
      setSelectedIcon('/settings');
    } else if (location.pathname === '/admin') {
      setSelectedIcon('');
    }
    // Add more route checks if needed
  }, [location]);

  return (
    <div className="App">
      {/* TOP HEADER (hamburger on left) */}
      {isLoggedIn && !isShareRoute && (
        <Header
          toggleMenu={toggleMenu}
          buttonRef={buttonRef}
        />
      )}

      {/* HAMBURGER MENU (top-left, under button) */}
      {isLoggedIn && !isShareRoute && (
        <nav className={`menu ${isOpen ? 'open' : ''}`} ref={menuRef}>
          <ul>
            <li>
              <Link
                to="/about"
                onClick={() => {
                  setIsOpen(false);
                  setSelectedIcon(''); // Deselect bottom nav icons
                }}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/faq"
                onClick={() => {
                  setIsOpen(false);
                  setSelectedIcon('');
                }}
              >
                Help & FAQ
              </Link>
            </li>
            <li>
              <Link
                to="/feedback"
                onClick={() => {
                  setIsOpen(false);
                  setSelectedIcon('');
                }}
              >
                Feedback
              </Link>
            </li>
            {isAdmin && (
              <li>
                <Link
                  to="/admin"
                  onClick={() => {
                    setIsOpen(false);
                    setSelectedIcon('');
                  }}
                >
                  <FontAwesomeIcon icon={faLineChart} /> Admin
                </Link>
              </li>
            )}
            <li>
              <button
                onClick={() => {
                  logout();
                  setIsOpen(false);
                }}
                className="logout-button"
              >
                Logout
              </button>
            </li>
          </ul>
        </nav>
      )}

      {/* MAIN CONTENT / ROUTES */}
      <div className="App-content">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />

          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route path="/items" element={<ProtectedRoute> <Items previewMode={false} /></ProtectedRoute>} />
          <Route path="/wishes" element={<ProtectedRoute><Wishes previewMode={false} /></ProtectedRoute>} />

          <Route
            path="/about"
            element={
              <ProtectedRoute>
                <About />
              </ProtectedRoute>
            }
          />
          <Route
            path="/faq"
            element={
              <ProtectedRoute>
                <FAQ />
              </ProtectedRoute>
            }
          />
          <Route
            path="/myactivity"
            element={
              <ProtectedRoute>
                <MyActivity />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/feedback"
            element={
              <ProtectedRoute>
                <Feedback />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <Admin />
              </AdminRoute>
            }
          />

          {/* NEW: Public route for shared library */}
          <Route path="/share/:sharedId" element={<SharedLibrary />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>

      {/* Add Item & Wish Modals (hidden by default) */}
      {showAddItemModal && (
        <AddItemModal
          show={showAddItemModal}
          onClose={() => setShowAddItemModal(false)}
          giveaway_flag={isGiveaway}
        />
      )}
      {showAddWishModal && (
        <AddWishModal
          show={showAddWishModal}
          onClose={() => setShowAddWishModal(false)}
          giveaway_flag={isGiveaway}
          type="wish"
        />
      )}

      {/* BOTTOM NAV */}
      {isLoggedIn && !isShareRoute && (
        <BottomNav
          toggleMenu={toggleMenu}
          isOpen={isOpen}
          notifications={notifications}
          totalNotifications={totalNotifications} // Pass the total count
          pendingRequestsCount={pendingRequestsCount} // Pass pending requests count for Network
          previewMode={previewMode}
          showNavRef={showNavRef}
          selectedIcon={selectedIcon}
          setSelectedIcon={setSelectedIcon}
          onOfferClick={handleOfferClick}
          onLendClick={handleLendClick}
          onWishClick={handleWishClick}
        />
      )}
    </div>
  );
}

// App is our top-level, wrapping everything in <BrowserRouter>
function App() {
  return (
    <UserProvider>
      <NotificationProvider> {/* Wrap with NotificationProvider */}
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </NotificationProvider>
    </UserProvider>
  );
}

export default App;
