import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { API_URL } from './constants';
import './ChangePassword.css';

function ChangePassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const forced = location.state && location.state.forced;
  const prefillEmail = location.state && location.state.email;

  const [email, setEmail] = useState(prefillEmail || '');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      // Construct payload. For forced changes, omit oldPassword.
      const payload = { email, newPassword };
      if (!forced) {
        payload.oldPassword = oldPassword;
      }
      
      const response = await fetch(`${API_URL}/change-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      setMessage(data.message);
      if (response.ok) {
        // After successful password change, redirect to dashboard (or items page)
        navigate('/items');
      }
    } catch (error) {
      setMessage('Error: ' + error.message);
    }
  };

  return (
    <div className="change-password-container">
      <h2>{forced ? "Set a New Password" : "Change Password"}</h2>
      <form onSubmit={handleChangePassword}>
        {!prefillEmail && (
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        )}
        {/* Show the old password field only when not forced */}
        {!forced && (
          <input
            type="password"
            placeholder="Current Password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        )}
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>
      {message && <p>{message}</p>}
      <Link to="/items">Back to Dashboard</Link>
    </div>
  );
}

export default ChangePassword;
