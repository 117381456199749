// src/pages/Admin.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheck, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import './Admin.css';
import { API_URL } from './constants';

/** CollapsibleSection helper */
function CollapsibleSection({ title, children, defaultOpen = false }) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible-section">
      <div 
        className={`section-header ${isOpen ? 'open' : ''}`}
        onClick={toggleSection}
        role="button"
        tabIndex={0}
        aria-expanded={isOpen}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleSection();
          }
        }}
      >
        <h2 className="section-title">{title}</h2>
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="toggle-icon" />
      </div>
      {isOpen && <div className="section-content">{children}</div>}
    </div>
  );
}

/** Utility for "Jan 25, 2025" type formatting. */
function formatDate(dateString) {
  if (!dateString) return 'N/A';
  const dateObj = new Date(dateString);
  return dateObj.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
}

/**
 * "Recently Active Users" 
 * - No mention of "top 3"
 * - Still calling your same endpoint `/api/admin/top-users` (or any name)
 * - Omit user ID column
 */
function RecentlyActiveUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    setError('');
    try {
      // Suppose your server endpoint is /api/admin/top-users. 
      // It might limit results to 3 or more, but we won't mention "top 3" here.
      const response = await axios.get('/api/admin/top-users');
      // e.g. [ { user_id, name, last_login }, ...]
      setUsers(response.data);
    } catch (err) {
      console.error('Failed to fetch recently active users:', err);
      setError('Could not fetch recently active users.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <p>Loading recently active users...</p>}
      {error && <p className="error-message">{error}</p>}
      {!loading && !error && users.length === 0 && (
        <p>No recent activity from non-admin users.</p>
      )}
      {!loading && !error && users.length > 0 && (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>
                Name
              </th>
              <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>
                Last Login
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((u, index) => (
              <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
                <td style={{ padding: '8px' }}>{u.name || 'Unknown'}</td>
                <td style={{ padding: '8px' }}>{formatDate(u.last_login)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

/**
 * Group Subscriptions 
 * - Omit user ID column
 * - Sort by first name if not done in backend
 */
function GroupSubscriptions() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    fetchGroupSubscriptions();
  }, []);

  const fetchGroupSubscriptions = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get('/api/admin/group-subscriptions');
      // example shape: [ { user_id, user_name, groups: [ {group_id, group_name}, ... ] }, ...]
      // We can do client-side sort by first name:
      const sorted = response.data.sort((a, b) => {
        const aName = a.user_name?.split(' ')[0] || a.user_name;
        const bName = b.user_name?.split(' ')[0] || b.user_name;
        return aName.localeCompare(bName);
      });
      setSubscriptions(sorted);
    } catch (err) {
      console.error('Failed to fetch group subscriptions:', err);
      setError('Could not fetch group subscriptions.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: '10px' }}>
      {loading && <p>Loading group subscriptions...</p>}
      {error && <p className="error-message">{error}</p>}
      {!loading && !error && subscriptions.length === 0 && (
        <p>No non-admin users are subscribed to any groups yet.</p>
      )}
      {!loading && !error && subscriptions.length > 0 && (
        <table className="group-subscriptions-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>
                User
              </th>
              <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd' }}>
                Groups Subscribed
              </th>
            </tr>
          </thead>
          <tbody>
            {subscriptions.map((user, idx) => (
              <tr key={idx} style={{ borderBottom: '1px solid #ddd' }}>
                <td style={{ padding: '8px' }}>{user.user_name}</td>
                <td style={{ padding: '8px' }}>
                  {user.groups.map(g => g.group_name).join(', ')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

/**
 * Engagement Insights 
 * - default timeFrame => 'weekly'
 * - time range label e.g. "Jan 1, 2025 – Jan 7, 2025"
 */
function ActivityTrends() {
  const [timeFrame, setTimeFrame] = useState('weekly'); 
  const [data, setData] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('all');
  const [timeRangeLabel, setTimeRangeLabel] = useState('');

  useEffect(() => {
    fetchActiveUsers();
  }, []);

  useEffect(() => {
    fetchActivityData(timeFrame, selectedUser);
    updateTimeRangeLabel(timeFrame);
  }, [timeFrame, selectedUser]);

  // Helper to format the "time range" label
  const updateTimeRangeLabel = (frame) => {
    const now = new Date();
    let start, end;

    if (frame === 'daily') {
      setTimeRangeLabel(`Today (${now.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })})`);
      return;
    } else if (frame === 'weekly') {
      end = now;
      start = new Date(now);
      start.setDate(now.getDate() - 6);
    } else if (frame === 'monthly') {
      end = now;
      start = new Date(now);
      start.setDate(now.getDate() - 29);
    } else if (frame === 'yearly') {
      end = now;
      start = new Date(now);
      start.setFullYear(now.getFullYear() - 1);
    }

    if (start && end) {
      const startStr = start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const startYr = start.getFullYear();
      const endStr = end.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const endYr = end.getFullYear();

      if (startYr === endYr) {
        setTimeRangeLabel(`${startStr}, ${startYr} – ${endStr}, ${endYr}`);
      } else {
        setTimeRangeLabel(`${startStr}, ${startYr} – ${endStr}, ${endYr}`);
      }
    }
  };

  const fetchActiveUsers = async () => {
    try {
      const response = await axios.get('/api/active_users');
      setActiveUsers(response.data); 
    } catch (error) {
      console.error('Failed to fetch active users:', error);
    }
  };

  const fetchActivityData = async (frame, user) => {
    try {
      const response = await axios.get(`/api/user_activities?timeFrame=${frame}&userId=${user}`);
      setData(response.data);
    } catch (error) {
      console.error('Failed to fetch activity data:', error);
    }
  };

  const handleTimeFrameChange = (frame) => {
    setTimeFrame(frame);
  };

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const timeFrameOptions = [
    { label: 'D', value: 'daily' },
    { label: 'W', value: 'weekly' },
    { label: 'M', value: 'monthly' },
    { label: 'Y', value: 'yearly' }
  ];

  const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthLabels = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];

  const formatXAxis = (tickItem) => {
    if (timeFrame === 'weekly') {
      return dayLabels[tickItem - 1] || tickItem;
    }
    if (timeFrame === 'yearly') {
      return monthLabels[tickItem - 1] || tickItem;
    }
    return tickItem;
  };

  const calculateTicks = (dataArr, key) => {
    if (!dataArr || dataArr.length === 0) return [];
    const maxCount = Math.max(...dataArr.map(d => d[key] || 0));
    const step = Math.ceil(maxCount / 5) || 1;
    return Array.from({ length: 5 }, (_, i) => (i + 1) * step);
  };

  return (
    <div className="activity-trends">
      <div className="controls">
        {timeFrameOptions.map(frame => (
          <button
            key={frame.value}
            onClick={() => handleTimeFrameChange(frame.value)}
            className={`time-frame-button ${timeFrame === frame.value ? 'active' : ''}`}
          >
            {frame.label}
          </button>
        ))}
        <select onChange={handleUserChange} value={selectedUser} className="user-select">
          <option value="all">All Users (excluding admins)</option>
          {activeUsers.map(user => (
            <option key={user.user_id} value={user.user_id}>
              {user.user_name}
            </option>
          ))}
        </select>
      </div>

      {/* Show the time range label */}
      <div style={{ textAlign: 'center', marginTop: '10px', color: '#555', fontSize: '0.85rem' }}>
        {timeRangeLabel}
      </div>

      <div className="charts">
        <div className="chart-container">
          <h4>App Engagement</h4>
          <ResponsiveContainer width="100%" height={250}>
            {data && data.length > 0 ? (
              <BarChart data={data} margin={{ top: 20, right: 20, left: 0, bottom: 20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" tickFormatter={formatXAxis} />
                <YAxis ticks={calculateTicks(data, 'count')} />
                <Tooltip />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            ) : (
              <p className="no-data">No data available.</p>
            )}
          </ResponsiveContainer>
        </div>

        <div className="chart-container">
          <h4>Distinct Users</h4>
          <ResponsiveContainer width="100%" height={250}>
            {data && data.length > 0 ? (
              <BarChart data={data} margin={{ top: 20, right: 20, left: 0, bottom: 20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" tickFormatter={formatXAxis} />
                <YAxis ticks={calculateTicks(data, 'distinct_users')} />
                <Tooltip />
                <Bar dataKey="distinct_users" fill="#82ca9d" />
              </BarChart>
            ) : (
              <p className="no-data">No data available.</p>
            )}
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

function TestEmailSender() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const sendTestEmail = async () => {
    setMessage('');
    
    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setMessage('Please enter a valid email address.');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/send-email`, { email, name: "Test User" });

      if (response.data.message) {
        setMessage(`Success: Email sent to ${email}`);
        setEmail('');
      }
    } catch (error) {
      console.error('Failed to send test email:', error);
      setMessage('Failed to send email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="test-email-container">
      <input
        type="email"
        placeholder="Enter test email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="test-email-input"
        aria-label="Test Email"
      />
      <button 
        onClick={sendTestEmail} 
        className="test-email-button"
        disabled={loading}
      >
        <FontAwesomeIcon icon={faPaperPlane} /> {loading ? 'Sending...' : 'Send Test Email'}
      </button>
      {message && <p className="test-email-message">{message}</p>}
    </div>
  );
}

function Admin() {
  const [email, setEmail] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleApproveEmail = async () => {
    setStatusMessage('');
    setErrorMessage('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post('/api/preapprove-email', { email }, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data.message) {
        setStatusMessage(response.data.message);
        setEmail('');
      }
    } catch (error) {
      console.error('Error approving email:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('Failed to approve email. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="admin-dashboard">
      {/* 1) Recently Active Users => collapsible, but defaultOpen => true */}
      <CollapsibleSection title="Recently Active Users" defaultOpen={true}>
        <RecentlyActiveUsers />
      </CollapsibleSection>

      {/* 2) Group Subscriptions => defaultOpen=false */}
      <CollapsibleSection title="Group Subscriptions" defaultOpen={false}>
        <GroupSubscriptions />
      </CollapsibleSection>

      {/* 3) Engagement Insights => defaultOpen=false */}
      <CollapsibleSection title="Engagement Insights" defaultOpen={false}>
        <ActivityTrends />
      </CollapsibleSection>

      {/* 4) Preapprove user => defaultOpen=false */}
      <CollapsibleSection title="Preapprove a New User" defaultOpen={false}>
        <div className="preapprove-form">
          <input
            type="email"
            placeholder="Enter user email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="preapprove-input"
            aria-label="User Email"
          />
          <button 
            onClick={handleApproveEmail} 
            className="preapprove-button"
            disabled={isSubmitting}
          >
            <FontAwesomeIcon icon={faCheck} /> {isSubmitting ? 'Approving...' : 'Approve'}
          </button>
        </div>
        {statusMessage && <p className="success-message">{statusMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </CollapsibleSection>

      <CollapsibleSection title="Send Test Email" defaultOpen={false}>
        <TestEmailSender />
      </CollapsibleSection>

    </div>
  );
}



export default Admin;
