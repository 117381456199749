import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { API_URL } from './constants';

import './App.css';
import './Login.css';
import PeopleIcon from '@mui/icons-material/People';
import LoopIcon from '@mui/icons-material/Loop';

const LoginIcon = () => (
  <div className="login-icon" style={{ position: 'relative' }}>
    <PeopleIcon style={{ fontSize: 24, color: '#4CAF50' }} />
    <LoopIcon
      style={{
        position: 'absolute',
        top: '50%',
        left: '100%',
        transform: 'translate(-50%, -50%)',
        fontSize: 12,
        color: '#4CAF50',
        opacity: 0.6,
      }}
    />
  </div>
);

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth(); // Get login function from context

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
        credentials: 'include', // Needed to include the cookie in subsequent requests
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.token); // Store the token
        localStorage.setItem('userId', data.userId.toString()); // Store the user ID

        login(); // Update the login state to true

        if (data.isTemporary) {
          // Force password change if logged in with a temporary password.
          // Pass along the email and a flag indicating forced change.
          navigate('/change-password', { state: { email: data.email, forced: true } });
        } else {
          navigate('/items', { state: { username, userId: data.userId } });
        }
      } else {
        alert(data.message);
      }
    } catch (error) {
      alert('Login failed: ' + error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="branding">
        <h1>Community Cycle</h1>
        <span>
          Your Community's Library of Things <LoginIcon />
        </span>
      </div>
      <form className="login-form" onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Email or Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
        <p className="links">
          New user? <Link to="/register">Register here</Link>
        </p>
        <p className="links">
          <Link to="/reset-password">Forgot Password?</Link>
        </p>
      </form>
    </div>
  );
}

export default Login;
